import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import React from 'react'
import { HiChevronRight } from 'react-icons/hi'

type breadcrumbsProps = {
  shop?: string,
  category?: any,
  categoryHref?: any, 
  brand?: any,
  product?: string
}

export const ProductBreadcrumb = ({shop, category, brand, product, categoryHref}: breadcrumbsProps) => {

  return (
    <Breadcrumb
      fontSize="sm"
      fontWeight="medium"
      color="gray.800"
      separator={<Box as={HiChevronRight} color="blue.400" />}
    >

      { shop &&  <BreadcrumbItem key={shop} >
                <BreadcrumbLink href="/">{shop}</BreadcrumbLink>
      </BreadcrumbItem>}
      
     { category && <BreadcrumbItem key={category} >
          <BreadcrumbLink href={`/category/` + categoryHref}>{category}</BreadcrumbLink>
      </BreadcrumbItem>}

        { brand && <BreadcrumbItem key={brand} >
          <BreadcrumbLink href="">{brand}</BreadcrumbLink>
      </BreadcrumbItem>}

         { product && <BreadcrumbItem key={product} >
          <BreadcrumbLink href="">{product}</BreadcrumbLink>
      </BreadcrumbItem>}
   
    
    </Breadcrumb>
  )
}