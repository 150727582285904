import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Link,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import AddToCart from '../AddToCart'
import useAuth from '../hooks/useAuth'

export const RelatedProductsCard = ({props}: RelatedProductsProps) => {
  const { loggedIn, user } = useAuth();
  return (
    <Stack spacing={useBreakpointValue({ base: '4', md: '5' })} height="100%" justifyContent="space-between" >
      <Link href={`/product/${props.slug}`} _hover={{textDecoration: "none !important"}}>
      <Box position="relative" maxW={{base: "100%", lg:"208px"}}>
        <AspectRatio ratio={4 / 3}>
          <Image
            src={props.image?.sourceUrl}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius={useBreakpointValue({ base: 'md', md: 'xl' })}
          />
        </AspectRatio>
      </Box>
      <Stack mt="24px">
        <Stack spacing="1">
          <Text fontWeight="medium" color={useColorModeValue('gray.700', 'gray.400')}>
            {props.name}
          </Text>
            <Text fontWeight="700" color="blue.500">
            {props.price}
          </Text>

        </Stack>
        </Stack>
        </Link>
      <Stack align="center">
      {!loggedIn ? (
          <Link className="chakra-button css-1oloaee" href='/log-in'  _hover={{textDecoration: "none"}} display='block' margin="0px auto !important" ><Button bg="blue.500" variant="solid" size="lg" mt="16px !important" > Sign In</Button></Link>
            ) : (
              <AddToCart productId={props.databaseId} />
            )}
          {/* <AddToCart productId={props.databaseId} />  */}
      </Stack>
    </Stack>  
  )
}

type RelatedProductsProps = {
  props: {
    name: String, 
    slug: String,
    price: string,
    databaseId: number,
    image?: {
      sourceUrl?: string
    },
    productTags: {
      nodes?: []
      }
    }
}